import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import ReactPlayer from "react-player";
import styled from "styled-components";
import Img from "gatsby-image";
import AlserkalAdvisoryWrapper from "../components/AlserkalAdvisoryWrapper";
import { PerspectivesDiv } from "../components/Perspectives/elements";
import ArrowRightLine from "../components/icons/ArrowRightLine";
import { H3, H4Big, H6Medium } from "../components/Typography";
import { Link } from "gatsby";
import {  BigButton } from "./../components/Buttons";
import {
  OurStoryBottom,
} from "./../components/OurStoryStyles/elements";
import emailjs from 'emailjs-com';
import { GlobalStyle } from "../globalStyles";
import CloseButton from "../components/icons/CloseButton";
import { FoundationFrontText } from "../components/FoundationStyles/elements";

const OuterDiv = styled.div`
background-color: #ffffff;
padding: 0px 100px 0 100px;
margin-top:50px;
@media (max-width: 767px) {
    padding:0px;
    & div{
        & h3{
            margin: 0px;
            font-size: 30px;
            margin-bottom: 25px;
        }
    }
}
}
`;
const ContactUsAdvisoryWrapper = styled.div`
 
  width: 35%;
  position:relative;
  background-color: rgba(255, 255, 255, 0.95);
  padding-top: 50px;
  padding-bottom: 0px;
  margin-bottom: 5px;
  position: fixed; /* Stay in place */
  z-index: 99 !important; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  & svg {
    float: right;
  }
  & h3{
    color: #ff644e;
    margin: 0px 0px 20px;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.32px;
    font-weight: 600;
    text-align: left;
  }
  & div{
    margin: auto;
    padding: 25px;
    border: 1px solid #888;
    width: 80%;
    background-color: #fefefe;
    max-height: 80vh;
    overflow-y: scroll;
    max-width: 460px;
  }
  & form{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background-color: #fefefe;
   
    & p {
      display: flex;
      margin-bottom:15px;
      justify-content: space-between;
      & > span {
        display: inline-block;
        min-width: 5px;
      }
    }
    & label {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: var(--text);
      font-size: 15px;
      line-height: 1.4;
      letter-spacing: 0.3px;
      & input,
      & textarea {
        border: 1px solid #000000;
        box-sizing: border-box;
        height: 36px;
        padding: 8px;
        margin-top: 4.5px;
        line-height: 1.4;
        letter-spacing: 0.3px;
        width: 100%;
        font-family: var(--headerFont);
        font-size: 15px;
        color: var(--black);
      }
      & textarea {
        height: 144px;
      }
    }
    & input[type="submit"],
    & button {
      color: #ff644e;
      border: 1px solid #ff644e;
      width: 200px;
      height: 36px;
      font-size: 15px;
      font-family: var(--headerFont);
      line-height: 21px;
      font-weight: 500;
      margin-top: 35px;
      margin-bottom: 10px;
      background-color: var(--white);
      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 1px;
    & > form {
      margin-bottom: 0;
      & > div {
        padding: 0 !important;
      }
    }
  }
`;
const ButtonOuterDiv = styled.div`
margin: auto;
    width: 50%;
    padding: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
}
`;
const PerspectiveTile = ({ title, author, image, link }) => {
    return (
      <div>
        <Link to={link}>
          {image && image.cardImage ? (
            image.cardImage.childImageSharp ? (
              <Img fixed={image.cardImage.childImageSharp.fixed} alt={title} />
            ) : (
              <img src={image.cardImage.publicURL} alt={title} />
            )
          ) : (
            <div className="fallback" />
          )}
        </Link>
        <div>
          <H4Big>
            <Link to={link}>{title} </Link>
          </H4Big>
          {author ? (
            <H6Medium>
              <Link to={link}>{author}</Link>
            </H6Medium>
          ) : null}
          <ArrowRightLine color={"var(--green)"} />
        </div>
      </div>
    );
  };


const alserkalAdvisroyBetaPage = () =>{
    const alserkalAdvisoryQuery = useStaticQuery(graphql`
        {
            craftAlserkalAdvisoryAlserkalAdvisoryEntry {
                subtitle
                title
                seoTitle
                seoDescription
                editorialVideoRemoteVideoUrl
                caption
                callToAction
                venuePdfForDownload {
                  url
                }
                richTextField
                featuredWorks {
                  ... on Craft_event_event_Entry {
                    id
                    title
                    slug
                    typeHandle
                    rectangleThumbnails {
                      ... on Craft_editorialAssets_Asset {
                        cardImage: localFile {
                          publicURL
                          childImageSharp {
                            fixed(width: 400, height: 230) {
                              src
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                    headerImage {
                      ... on Craft_editorialAssets_Asset {
                        cardImage: localFile {
                          publicURL
                          childImageSharp {
                            fixed(width: 400, height: 230) {
                              src
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on Craft_articles_sounds_Entry {
                    id
                    title
                    slug
                    typeHandle
                    rectangleThumbnails {
                      url
                      ... on Craft_editorialAssets_Asset {
                        cardImage: localFile {
                          publicURL
                          childImageSharp {
                            fixed(width: 400, height: 230) {
                              src
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                    headerImage {
                      ... on Craft_editorialAssets_Asset {
                        cardImage: localFile {
                          publicURL
                          childImageSharp {
                            fixed(width: 400, height: 230) {
                              src
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on Craft_articles_words_Entry {
                    id
                    title
                    slug
                    typeHandle
                    rectangleThumbnails {
                      url
                      ... on Craft_editorialAssets_Asset {
                        cardImage: localFile {
                          publicURL
                          childImageSharp {
                            fixed(width: 400, height: 230) {
                              src
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                    headerImage {
                      ... on Craft_editorialAssets_Asset {
                        cardImage: localFile {
                          publicURL
                          childImageSharp {
                            fixed(width: 400, height: 230) {
                              src
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on Craft_articles_videos_Entry {
                    id
                    title
                    slug
                    typeHandle
                    rectangleThumbnails {
                      url
                      ... on Craft_editorialAssets_Asset {
                        cardImage: localFile {
                          publicURL
                          childImageSharp {
                            fixed(width: 400, height: 230) {
                              src
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                    headerImage {
                      ... on Craft_editorialAssets_Asset {
                        cardImage: localFile {
                          publicURL
                          childImageSharp {
                            fixed(width: 400, height: 230) {
                              src
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on Craft_articles_images_Entry {
                    id
                    title
                    slug
                    typeHandle
                    rectangleThumbnails {
                      url
                      ... on Craft_editorialAssets_Asset {
                        cardImage: localFile {
                          publicURL
                          childImageSharp {
                            fixed(width: 400, height: 230) {
                              src
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                    headerImage {
                      ... on Craft_editorialAssets_Asset {
                        cardImage: localFile {
                          publicURL
                          childImageSharp {
                            fixed(width: 400, height: 230) {
                              src
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                  }
                }
                upcomingProgrammesListing {
                  ... on Craft_event_event_Entry {
                    id
                    title
                    slug
                    typeHandle
                    rectangleThumbnails {
                      ... on Craft_editorialAssets_Asset {
                        cardImage: localFile {
                          publicURL
                          childImageSharp {
                            fixed(width: 400, height: 230) {
                              src
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                    headerImage {
                      ... on Craft_editorialAssets_Asset {
                        cardImage: localFile {
                          publicURL
                          childImageSharp {
                            fixed(width: 400, height: 230) {
                              src
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                  }
                  
                }
                headerImage {
                  ... on Craft_editorialAssets_Asset {
                    SEOImage: localFile {
                      childImageSharp {
                        fixed(width: 1200) {
                          src
                        }
                      }
                    }
                    wide: localFile {
                      publicURL
                      childImageSharp {
                        fixed(width: 1240, height: 700, fit: FILL) {
                          src
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
                recommendationEvents {
                  ... on Craft_event_event_Entry {
                    id
                    title
                    eventEndDateTime
                    eventStartDateTime
                    eventShortDescription
                    slug
                    eventType
                    eventLocation {
                      title
                      slug
                    }
                    headerImage {
                      url
                      ... on Craft_editorialAssets_Asset {
                        cardImage: localFile {
                          publicURL
                          childImageSharp {
                            fixed(width: 290) {
                              src
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                    }
                  }
                }
        
              }
        }
    `).craftAlserkalAdvisoryAlserkalAdvisoryEntry;
    const {
        title,
        richTextField,
        subtitle,
        featuredWorks,
        seoTitle,
        seoDescription,
        caption,
        headerImage,
        callToAction,
        venuePdfForDownload,
        editorialVideoRemoteVideoUrl,
        recommendationEvents,
      } = alserkalAdvisoryQuery;
    
    var seoTitleAdv = alserkalAdvisoryQuery.seoTitle ? alserkalAdvisoryQuery.seoTitle : null;
    var seoDescAdv = alserkalAdvisoryQuery.seoDescription ? alserkalAdvisoryQuery.seoDescription : null;
  
    
    
  //const [showModal, SetShowModal] = useState(true)
  const [showPopup, SetShowPopup] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_b63obcc', 'template_ew3a1ue', e.target, '1oiFBKwf2u2uXZh0F')
      .then((result) => {
        SetShowPopup(false)
        //SetShowModal(prev => !prev)
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  }


  return (
    <AlserkalAdvisoryWrapper
      useStayInformed={true}
      selected={false}
      selection={0}
      title={
        <React.Fragment>
          Alserkal
          <br /> Advisory
        </React.Fragment>
      }
    >
      <div >
      {richTextField && richTextField.length>0 ? (
        <FoundationFrontText
        dangerouslySetInnerHTML={{ __html: richTextField }}
        style={{ paddingBottom: "20px",position:'relative' }}
      />
      ) : (
        null
      )}
      
      
      </div>
      
      <OuterDiv>
        <PerspectivesDiv>
          <H3>Featured Work</H3>
          <div>
            {alserkalAdvisoryQuery.featuredWorks.map((tile, index) =>
            (
              <PerspectiveTile
                key={index}
                title={tile.title}
                link={`/${tile.typeHandle}/${tile.slug}`}
                image={
                  tile.rectangleThumbnails && tile.rectangleThumbnails.length
                    ? tile.rectangleThumbnails[0]
                    : (tile.headerImage && tile.headerImage.length ? tile.headerImage[0] : null)
                }
              />

            ))}
          </div>
        </PerspectivesDiv>
        <PerspectivesDiv>
          <H3>Upcoming Programmes</H3>
          <div>
          {alserkalAdvisoryQuery.upcomingProgrammesListing.map((tile, index) => 
            (
              <PerspectiveTile
                key={index}
                title={tile.title} 
                link={`/${tile.typeHandle}/${tile.slug}`}
                image={
                    tile.rectangleThumbnails && tile.rectangleThumbnails.length
                    ? tile.rectangleThumbnails[0]
                    : (tile.headerImage && tile.headerImage.length ? tile.headerImage[0]: null)
                    }
              />
              
            ))}
          </div>
        </PerspectivesDiv>
      </OuterDiv>
      <ButtonOuterDiv style={{paddingBottom:'50px'}}>
      <BigButton
            color={"var(--purple)"}
            style={{ zIndex: 1 }}
            // onClick={(e) => {
            //   e.preventDefault();
            //   navigate(`/contact-us`);
            // }}
            onClick={() => {
              SetShowPopup(true);
            }}
          >
            {callToAction || "Get in touch"}
          </BigButton>
      </ButtonOuterDiv>
      <OurStoryBottom>
        {showPopup
          ?
          <ContactUsAdvisoryWrapper>
            <div>
              
            <CloseButton
                color="var(--red)"
                onClick={() => {
                  SetShowPopup(false);
                }}
              />
            <h3>Get In Touch</h3>
            <form onSubmit={sendEmail} >
              <p>
                <label>
                  <input type="hidden" name="from_name" value="info@alserkal.online"></input>
                  First name<input type="text" name="First_Name" required></input>
                </label>
                <span>&nbsp;</span>
                <label>
                  Last name<input type="text" name="Last_name" required></input>
                </label>
              </p>
              <p>
                <label>
                  Email address<input type="email" name="Email_address" required></input>
                </label>
              </p>
              <p>
                <label>
                  Mobile number<input type="text" name="Mobile_number" required></input>
                </label>
              </p>
              <p>
                <label>
                  Company name
                  <input type="text" name="Company_name" required></input>
                </label>
              </p>
              <p>
                <label>
                  Job title
                  <input type="text" name="Job_title" required></input>
                </label>
              </p>
              <p>
                <label>
                  We’re curious to know more…
                  <textarea name="Message" required />
                </label>
                </p>
              <input type="submit" value="Submit" />
              
            </form>
            </div>
          </ContactUsAdvisoryWrapper>
          : null
        }
        
        
        <GlobalStyle />


       
      </OurStoryBottom>
    </AlserkalAdvisoryWrapper>
  );
};

export default alserkalAdvisroyBetaPage;